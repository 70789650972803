<template>
  <div class="order">
    <h1 class="text-h4 mb-3 text-center">Display Single Order</h1>
    <div v-if="loading">
      <v-progress-linear indeterminate color="green"></v-progress-linear>
    </div>
    <v-card class="mx-auto">
      <v-card-title>
        <span class="text-h4 mr-3">{{ order.orderNumber }}</span>
        <span class="text-h5 indigo--text font-weight-700">{{
          order.customer
        }}</span>
      </v-card-title>
      <v-card-text>
        <h2 class="text-h5">Items:</h2>
        <div v-for="item in order.items" :key="item._id">
          <h3 class="text-h5">{{ item.model }} x {{ item.quantity }}</h3>
        </div>
      </v-card-text>
      <v-divider></v-divider>
      <order-timeline :status="order.status" @toggleState="toggleStatus" />
    </v-card>
    <v-btn :to="{ name: 'Orders' }" large outlined color="primary" class="my-4">
      Back
    </v-btn>
    <v-btn @click="toggleRelease" large outlined color="primary" class="ma-4">
      Update
    </v-btn>
    <v-btn @click="deleteOrder" large outlined color="error" class="ma-4">
      Delete
    </v-btn>
  </div>
</template>

<script>
import axios from "axios";
import OrderTimeline from "@/components/OrderTimeline.vue";
export default {
  components: { OrderTimeline },
  props: ["id"],

  data() {
    return {
      loading: false,
      order: {},
    };
  },
  methods: {
    toggleRelease() {
      console.log(this.order.status);
      axios
        .patch(
          `https://zsh-order-api.herokuapp.com/api/orders/status/${this.id}`,
          this.order
        )
        .then((response) => {
          let orderNumber = response.data.orderNumber;
          console.log(`Sucesfully updated MO-${orderNumber}`);
          this.$router.push({ name: "Orders" });
        });
    },
    toggleStatus(statusItem) {
      // console.log(`You clicked ${statusItem} !`);
      this.order.status[statusItem] = !this.order.status[statusItem];
    },
    deleteOrder() {
      axios
        .delete(`https://zsh-order-api.herokuapp.com/api/orders/${this.id}`)
        .then((response) => {
          console.log(response.data);
          this.$router.push({ name: "Orders" });
        });
    },
  },
  created() {
    this.loading = true;
    axios
      .get(`https://zsh-order-api.herokuapp.com/api/orders/${this.id}`)
      .then((response) => {
        this.order = response.data;
        console.log(this.order);
        this.loading = false;
      });
  },
};
</script>

<style></style>
