<template>
  <v-timeline v-if="status" dense align-top>
    <v-timeline-item
      @click.native.stop="$emit('toggleState', 'stPO')"
      :color="status.stPO ? 'green' : 'red'"
      small
      fill-dot
    >
      Received PO
    </v-timeline-item>
    <v-timeline-item
      @click.native.stop="$emit('toggleState', 'stACK')"
      :color="status.stACK ? 'green' : 'red'"
      small
      fill-dot
    >
      Sent Docuemnts
    </v-timeline-item>
    <v-timeline-item
      @click.native.stop="$emit('toggleState', 'stOC')"
      :color="status.stOC ? 'green' : 'red'"
      small
      fill-dot
    >
      Received OC
    </v-timeline-item>
    <v-timeline-item
      @click.native.stop="$emit('toggleState', 'stDWG')"
      :color="status.stDWG ? 'green' : 'red'"
      small
      fill-dot
    >
      Received Drawing
    </v-timeline-item>
    <v-timeline-item
      @click.native.stop="$emit('toggleState', 'stDP')"
      :color="status.stDP ? 'green' : 'red'"
      small
      fill-dot
    >
      Received DP
    </v-timeline-item>
    <v-timeline-item
      @click.native.stop="$emit('toggleState', 'stReleased')"
      :color="status.stReleased ? 'green' : 'red'"
      small
      fill-dot
    >
      Order Released
    </v-timeline-item>
  </v-timeline>
</template>

<script>
export default {
  name: "OrderTimeline",
  props: {
    status: Object,
  },
};
</script>

<style></style>
